import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSearchBubbleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 36 36" width="24px" {...props}>
    <path
      d="M19.032 3C9.736 3 3 10.12 3 18.023c0 2.523.784 5.122 2.16 7.496.256.39.288.886.112 1.352L4.2 30.236c-.24.811.496 1.412 1.312 1.172l3.232-.902c.88-.27 1.568.075 2.384.541C13.464 32.34 16.376 33 19 33c7.936 0 16-5.754 16-15.022C35 9.984 28.12 3 19.032 3"
      fill="currentColor"
    />
    <path
      d="M14 17.5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0m7.8 3.3 2.7 2.7"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgSearchBubbleIcon);
export default Memo;
